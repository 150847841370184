import React, { useState } from "react";
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga'

import './Intro.css'

export default function Intro() {
        let [first,firstToggle] = useState(false);
        let [second,secondToggle] = useState(false);
        let [third,thirdToggle] = useState(false);
        let [animation,animationChange] =useState('appear');

        return (
            <div className="intro">

                <div className={
                    (first)
                    ?"intro-container-clicked"
                    :"intro-container"} 
                        onClick={()=>{
                            firstToggle(!first);
                            if(first){
                                setTimeout(() => {
                                    animationChange('');
                                  }, 1000);
                            }
                            else{
                                setTimeout(() => {
                                    animationChange('disappear');
                                  }, 1000);
                                setTimeout(() => {
                                    animationChange('');
                                  }, 1000);
                            }
                            
                            ReactGA.event({
                                category: "소개페이지 첫번째 글",
                                action: "소개페이지 첫번째 글 클릭",
                                label: "profile",
                            })

                        }
                }>
                    
                    <div className={
                            (first)
                            ?"intro-title-clicked"
                            :"intro-title"}>
                            <h5> 우리의 목적{
                                (first)
                                ?<span>↖</span>
                                :<span>↘</span>}</h5>
                    </div>
                </div>

                                
                
                <div className={
                    (first)
                    ? "intro-content "
                    : "intro-none"
                } >

                    <p>
                        당연하게 여겨지기 때문에 잊혀지기 쉬운
                        <br/>
                        소중한 일들과 소중한 사람들을 기억하는 것,
                        <br/>
                        그것이 새로운 당연함이 되는 문화를 만드는 기업
                        <br/>
                        <br/>
                    </p>
                        <div className="logo-gif">
                            <span>
                                we do not take for granted,
                            </span>
                            <img src={process.env.PUBLIC_URL + '/imgs/moving-G-2.gif'}></img>
                        </div>
                </div>


                <div className={ 
                    (second)
                    ?"intro-container-clicked"
                    :"intro-container"} 
                        onClick={()=>{
                            secondToggle(!second)
                            
                            ReactGA.event({
                                category: "소개페이지 두번째 글",
                                action: "소개페이지 두번째 글 클릭",
                                label: "profile",
                            })    
                        }
                }>

                    <div className={
                        (second)
                        ?"intro-title-clicked"
                        :"intro-title"}>
                        <h5>
                        우리가 해온 일    
                        {
                        (second)
                        ?<span>↖</span>
                        :<span>↘</span>}</h5>
                    </div>
                </div>
                
                
                <div className={
                    (second)
                    ? "intro-content"
                    : "intro-none"
                }>
                    <h5>'새로운 당연함'을 만드는<br/>첫번째 프로젝트</h5>
                    <h4>ThankyouCap Project</h4>
                    <p>
                        <div className="img-container">
                            <img src={process.env.PUBLIC_URL + '/imgs/hero-caps.jpg'}></img>
                            <span className="img-word">땡큐캡 프로젝트를 통해<br/>약 7,500명의 사람들과 함께</span>
                        </div>
                        <div className="img-container">
                            <img src={process.env.PUBLIC_URL + '/imgs/hero-cap.jpg'}></img>
                            <span className="img-word">약 8,000여명의 국가유공자분들꼐<br/>히어로캡을 선물했습니다.</span>
                        </div>
                        <h5>우리의 마음과 선물을
                        <br/>
                        모든 국가유공자분들께서 받으실 때까지
                        <br/>
                        땡큐캡 프로젝트는 계속됩니다.
                        </h5>
                    </p>

                </div>


                <div className={
                    (third)
                    ?"intro-container-clicked"
                    :"intro-container"} 
                        onClick={()=>{
                            thirdToggle(!third)
                            
                            ReactGA.event({
                                category: "소개페이지 세번째 글",
                                action: "소개페이지 세번째 글 클릭",
                                label: "profile",
                            })
                        }      
                }> 
                   
                    <div className={
                            (third)
                            ?"intro-title-clicked"
                            :"intro-title"}>
                            <h5>
                                우리가 해나갈 일
                                {(third)
                                ?<span>↖</span>
                                :<span>↘</span>}
                            </h5>
                    </div>
                    
                </div>

                <div className={
                    (third)
                    ? "intro-content third"
                    : "intro-none"
                }>
                    <h6>끊임없는 질문 THINK</h6>
                    <div>
                        우리 주변의 당연함에 대하여 한번 더 생각합니다. 당연하게 여겨질 수 있는 소중한 것들을 기억하고자 감사하며, 이 과정이 '새로운 당연함'이 되는 문화를 만들어갑니다. 그리고 이 새로운 당연함이 옳은 것인지 끊임없이 질문합니다.
                    </div>

                    <h6>멋잇는 도구의 제작 MAKE</h6>
                    <div>
                        우리의 삶에 있어 소비할만하고 소유할만한 가치가 있는 도구를 만듭니다. 이는 물리적, 문화적 수명을 충분히 갖춘 도구이자, 온전히 그 수명을 다할 수 있는 도구이며, 우리의 생각과 멋을 담은 도구입니다.
                    </div>

                    <h6>진정성 있는 전달 TRANSMIT</h6>
                    <div>
                        도구에 담긴 생각과 멋이 사람들에게 진정성있게 전달 될 수 있도록, 기술을 적극적으로 활용하고 발전시켜나갑니다. 우리는 기술을 통해 도구에 담긴 우리의 생각과 멋이 온전히 전해지길원합니다. 그리고 사람들의 마음속에서 새로운 생각으로 다시 태어나길 원합니다.
                    </div>

                </div>


                <footer className="intro-footer">
                    <div className="intro-footer-word">
                        <div>
                        FORGRANTED<br/>
                        © Copyright 2022 FORGRANTED(포그랜티드)<br/>
                        서울시 강남구 강남대로 154길 16, 신사빌딩 402호<br/>
                        402, Gangnam-daero 154-gil, Gangnam-gu, Seoul<br/>

                        </div>
                        <img src={process.env.PUBLIC_URL + '/imgs/G.png'}></img>
                    </div>
                </footer>

            </div>
        )

}