export const dataSlide = [
    {
        message: '이렇게나마 고마움을 전할 수 있어서 영광입니다, ️늘 감사합니다.'
       
    },
    
    {
        message: '지금의 우리나라를 있게 해주셔서 감사합니다.'
       
    },
    {
        message: '정말 감사하고 잊지 않고 살아가겠습니다.'
       
    },
    {
        message: '저희 외할아버지께도 다른분들께도 감사합니다!'
       
    },
    {
        message: '감사하다는 말 밖에 못하겠네요, 감사합니다.'
       
    },
    {
        message: '감사합니다❤️'
       
    },
]