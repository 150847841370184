import React from "react";
import { useHistory } from "react-router-dom";
import './Status.css';
import ReactGA from 'react-ga';



export default function Status(props){
    
    return (
        <div className="status-container">
            <div className="status-line">
                <span className={
                    (props.현황 === 1)
                    ? "status-circle yellow-bg"
                    : 'status-circle'
                    }>
                    <span className="stroke"></span>
    
                    <div className={props.현황===1&&"bang-head-stroke"}></div>
                    <div className={props.현황===1&&"bang-foot-stroke"}></div>
                </span>
                <span className={
                    props.현황===1
                    ?"status-sentence "
                    :'status-sentence not-active'}>기부품이 전달되기 전입니다.

                <p className={
                    props.현황===1
                    ?"status-detail"
                    :'none'}>스토어 '구매 확정'이 완료된 이후,<br></br>
                    기부 지역 선정이 진행됩니다.
                </p>
                </span>
            </div>

            <div className={
                    props.현황===2
                    ?"status-line status-line-2"
                    :"status-line"
            }>
                <span className={
                    props.현황===2
                    ?"status-circle yellow-bg"
                    :"status-circle"
                    }>
                    <span className={
                        props.현황===2
                        ?"stroke stroke-5rem"
                        :"stroke"
                    }
                    ></span>
                    <span className={
                        props.현황===2&&'dot'
                    }></span>
                </span>
                <span className={
                    props.현황===2
                    ?"status-sentence"
                    :"status-sentence not-active"}>기부품이 전달되고 있습니다.

                <p className={
                    props.현황===2
                    ?"status-detail"
                    :"none"}>기부 받으시는 분께<br></br>
                    기부품이 전달되는 중입니다.<br></br>
                    전달이 완료될 때까지<br></br>
                    조금만 기다려주세요 :)
                </p>
                </span>
            </div>

            <div className="status-line">
                <span className={props.현황===3
                 ? "status-circle blue-bg"
                 : 'status-circle'}>
                    <i className=
                    { (props.현황===3)
                        ? 'check-icon-container '
                        : 'none' 
                    }>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='white' d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
                </i>
                </span>
                <span className={props.현황===3
                 ? "status-sentence"
                 : 'status-sentence not-active'}>기부품 전달이 완료되었습니다.

                <p className={props.현황===3
                 ? "status-detail "
                 : 'none'}>전해주신 감사와 선물이 <br></br>
                 전달 완료 되었습니다.<br></br>
                 아래 인증서를 확인해주세요 :)<br></br>
                </p>
                </span>
            </div>

            <button type='button' className={
                props.현황 === 3
                ? 'present-button'
                : 'none'
                }
                onClick = {()=>{
                 props.setShowCerti(true);

                 ReactGA.event({
                    category: "선물인증서확인버튼",
                    action: "선물인증서 확인 클릭",
                    label: "profile",
                  })
                }}>
                선물 인증서 확인하기
            </button>

            <footer className={
                props.현황!==3
                ?"present-footer-container"
                :'none'}>
                <div className="footer_copyright">
                    함께 해주신 여러분들께 감사드립니다.<br/>
                    WE WON'T TAKE YOUR ACTION FOR GRANTED.
                </div>
            </footer>

        </div>
        
    )
}