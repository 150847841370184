import React from "react";
import { Link } from "react-router-dom";

import './Present.css'


export default function Present () {
    return(
            <div className='present-container'>
                <img src= {process.env.PUBLIC_URL + '/imgs/cap_loop.gif'} alt='image'></img>
                <p>구매한 도구</p>
                <h6>땡큐캡 화이트라벨</h6>
                <Link to={'/'} style={{textDecoration: 'none', fontSize: '0.8rem', fontWeight:'500'}}>다른번호 조회하기↘</Link>
            </div>
    )
}