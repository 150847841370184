import React from 'react';
import {dataNav} from './dataNav'
import './Header.css'
import ReactGA from 'react-ga'

export default function Header(props) {

    return (

        <header className="main-header">
            <div className='logo-wrapper' onClick={()=>{
                window.location.href='/'
            }}>
                <img src={process.env.PUBLIC_URL +'/imgs/logo.svg'} alt="forgranted_logo"/>
            </div>

            {
                dataNav.map((item, index)=>{
                    if(item.cName==='구입')
                        return (
                            <div key={index} className={item.cName}
                            onClick={()=>{
                                ReactGA.event({
                                    category: "SHOP메뉴버튼",
                                    action: "SHOP메뉴버튼 클릭",
                                    label: "profile",
                                  })
                            }}
                            ><a target='_blank' href={item.url}>{item.title}</a> </div>
                        )
                    else return (
                        <div key={index} className={item.cName}
                        onClick={()=>{
                            ReactGA.event({
                                category: index + "메뉴버튼",
                                action: index + "메뉴버튼 클릭",
                                label: "profile",
                              })
                        }}><a href={item.url}>{item.title}</a> </div>
                    )
                })    
            }
                
        </header>
    )
}

