export const dataNav = [
    {
        title: '소개',
        url: '/about',
        cName: 'nav-items'
    },
    {
        title: '상점',
        url: 'https://smartstore.naver.com/minganing',
        cName: 'nav-items'
    },
]