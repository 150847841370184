import React from "react";
import {dataSlide} from './dataSlide'
import './Slidebar.css'

export default function Slidebar(props){
    return(
        <>
            <div className="slide-bg">    
                    <div className="slide-bar">
                        
                        {
                            dataSlide.map((item,index)=>{
                                return(
                                    <span key={index} className="slide-bar-content">{item.message}</span>
                                )
                            })
                        }
                    </div>
            </div>
            <div className="slide-thx">
                <span>여러분이 전해주신 메시지입니다.😄</span>
            </div>
        </>
    )

}